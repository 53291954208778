export const CANDIDATE_SUBMITTING_INITIAL_AND_CORRECTIONS_STATUSES = new Set([
  'CANDIDATE_SUBMITTING',
  'CANDIDATE_SUBMITTING_CHANGES',
  'CANDIDATE_REVISING_AND_RESUBMITTING',
])

export const PRINCIPAL_ADVISOR_REVIEWING_STATUSES = new Set([
  'PRINCIPAL_ADVISOR_REVIEW',
  'PRINCIPAL_ADVISOR_FINAL_REVIEW',
  'PRINCIPAL_ADVISOR_REVIEWING_CHANGES',
])

// Statuses before setting oral exam date
export const STATUSES_BEFORE_SET_ORAL_EXAM_DATE = new Set([
  'CANDIDATE_SUBMITTING',
  'CANDIDATE_SUBMITTING_WITHDRAWN',
  'GRADUATE_SCHOOL_REVIEW',
  'CANDIDATE_SUBMITTING_GS_CHANGES',
  'PRINCIPAL_ADVISOR_REVIEW',
  'CANDIDATE_SUBMITTING_PA_CHANGES',
  'AWAITING_EXAMINERS_APPROVAL',
  'EXAMINERS_REVIEWING',
  'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION',

  'CANDIDATE_REVISING_AND_RESUBMITTING',
])

// Statuses before thesis examiner reviewing stage
export const STATUSES_BEFORE_EXAMINER_REVIEWING = new Set([
  'CANDIDATE_SUBMITTING',
  'CANDIDATE_SUBMITTING_WITHDRAWN',
  'GRADUATE_SCHOOL_REVIEW',
  'CANDIDATE_SUBMITTING_GS_CHANGES',
  'PRINCIPAL_ADVISOR_REVIEW',
  'CANDIDATE_SUBMITTING_PA_CHANGES',
  'AWAITING_EXAMINERS_APPROVAL',

  'CANDIDATE_REVISING_AND_RESUBMITTING',
])

// Statuses to show examiner examination reports to all parties
export const SHOW_EXAMINER_REPORTS_STATUSES = new Set([
  'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION',
  'AWAITING_ORAL_EXAMINATION',
])

// Statuses that the Examiners cannot be removed
export const EXAMINERS_CAN_BE_REMOVED_THESIS_STATUSES = new Set([
  'CANDIDATE_SUBMITTING',
  'CANDIDATE_SUBMITTING_WITHDRAWN',
  'GRADUATE_SCHOOL_REVIEW',
  'CANDIDATE_SUBMITTING_GS_CHANGES',
  'PRINCIPAL_ADVISOR_REVIEW',
  'CANDIDATE_SUBMITTING_PA_CHANGES',
  'AWAITING_EXAMINERS_APPROVAL',
  'EXAMINERS_REVIEWING',
  'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION',
  'CANDIDATE_REVISING_AND_RESUBMITTING',
])

// Statuses that the Chair cannot be changed
export const CHAIR_CAN_BE_CHANGED_THESIS_STATUSES = new Set([
  'CANDIDATE_SUBMITTING',
  'CANDIDATE_SUBMITTING_WITHDRAWN',
  'GRADUATE_SCHOOL_REVIEW',
  'CANDIDATE_SUBMITTING_GS_CHANGES',
  'PRINCIPAL_ADVISOR_REVIEW',
  'CANDIDATE_SUBMITTING_PA_CHANGES',
  'AWAITING_EXAMINERS_APPROVAL',
  'EXAMINERS_REVIEWING',
  'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION',
  'AWAITING_ORAL_EXAMINATION',
  'AWAITING_CHAIR_EXAMINATION_OUTCOME',
  'AWAITING_DEAN_OUTCOME',
  'CANDIDATE_SUBMITTING_CHANGES',
  'CANDIDATE_SUBMITTING_CHANGES_PA_ADDITIONAL',
  'CANDIDATE_SUBMITTING_CHANGES_CHAIR_ADDITIONAL',
  'PRINCIPAL_ADVISOR_REVIEWING_CHANGES',
  'CHAIR_REVIEWING_CHANGES',
  'CHAIR_SUBMITTING_CHANGES_DEAN_ADDITIONAL',
  'DEAN_REVIEWING_CHANGES',
  'CANDIDATE_REVISING_AND_RESUBMITTING',
])
