import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { GetThesisSubmissionQuery } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import EXAMINER_SAVES_FEEDBACK from 'src/lib/graphql/mutations/ExaminerSavesFeedback'
import EXAMINER_SUBMITS_FEEDBACK from 'src/lib/graphql/mutations/ExaminerSubmitsFeedback'
import EXAMINER_SUBMITS_FEEDBACK_RNR from 'src/lib/graphql/mutations/ExaminerSubmitsFeedbackRnR'
import useSnackbar from 'src/lib/hooks/useSnackbar'
import Sentry from 'src/lib/sentry'
import { isAssignedThesisExaminer } from 'src/lib/userAccessChecker'

import ExaminerWrittenFeedbackFormInputs from './components/FormInputs'
import {
  createDefaultValues,
  createRnRDefaultValues,
  createSubmissionInput,
  createThesisExaminerSummaryInput,
  ExaminationFormInputType,
  formValidationSchema,
  ReviseAndResubmitExaminationFormInputType,
  reviseAndResubmitFormValidationSchema,
} from './helpers'

type Props = {
  open: boolean
  onCancel: () => unknown
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  examiner: GetThesisSubmissionQuery['getThesisSubmission']['thesisSubmissionExaminers'][0] | null
  onReload: () => void
}

const ExaminerReviewDialog = ({ open, onCancel, submission, examiner, onReload }: Props) => {
  const { currentUser } = useAuth()
  const showSnackbar = useSnackbar()

  const [isPending, setIsPending] = useState<boolean>(false)

  const isRnR = submission.reviseAndResubmitOfId !== null

  // Since it's a shared component we need to use refetch instead of refetching from useMutation's refetchQueries
  const [examinerSubmitsFeedback] = useMutation(EXAMINER_SUBMITS_FEEDBACK)
  const [examinerSubmitsFeedbackRnR] = useMutation(EXAMINER_SUBMITS_FEEDBACK_RNR)
  const [examinerSavesFeedback] = useMutation(EXAMINER_SAVES_FEEDBACK)

  const formMethods = useForm({
    defaultValues: isRnR ? createRnRDefaultValues(examiner, submission) : createDefaultValues(examiner, submission),
    resolver: yupResolver(isRnR ? reviseAndResubmitFormValidationSchema() : formValidationSchema()),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (userInput: ExaminationFormInputType) => {
    setIsPending(true)
    try {
      await examinerSubmitsFeedback({
        variables: {
          id: examiner?.id,
          submissionId: submission.id,
          iterationId: submission.currentIteration.id,
          input: createSubmissionInput(userInput),
        },
      })

      showSnackbar('Successfully submitted your examination feedback.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not submit your examination feedback. Please try again later.', 'error')
    }

    setIsPending(false)
    onReload() // Since it's a shared component we need to use refetch instead of refetching from useMutation's refetchQueries
    onCancel()
  }

  const onSubmitRnR = async (userInput: ReviseAndResubmitExaminationFormInputType) => {
    setIsPending(true)
    try {
      await examinerSubmitsFeedbackRnR({
        variables: {
          id: examiner?.id,
          submissionId: submission.id,
          iterationId: submission.currentIteration.id,
          input: createThesisExaminerSummaryInput(userInput),
        },
      })

      showSnackbar('Successfully submitted your examination feedback.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not submit your examination feedback. Please try again later.', 'error')
    }

    setIsPending(false)
    onReload() // Since it's a shared component we need to use refetch instead of refetching from useMutation's refetchQueries
  }

  const onSave = async () => {
    setIsPending(true)
    const values = formMethods.getValues()

    try {
      await examinerSavesFeedback({
        variables: {
          id: examiner?.id,
          submissionId: submission.id,
          iterationId: submission.currentIteration.id,
          input: isRnR ? createThesisExaminerSummaryInput(values) : createSubmissionInput(values),
        },
      })

      showSnackbar('You have successfully saved your examination feedback.', 'success')
    } catch (error) {
      Sentry.captureException(error)
      showSnackbar('Could not save your examination summary. Please try again later.', 'error')
    }

    setIsPending(false)
    onReload() // Since it's a shared component we need to use refetch instead of refetching from useMutation's refetchQueries
  }

  const hasValidationError = !formMethods.formState.isValid
  const hasModifiedForm = formMethods.formState.isDirty

  // Form has to be yours AND not completed
  const isApprovedExaminer = isAssignedThesisExaminer(currentUser, examiner?.user?.id, examiner?.status)
  const readOnly = !(examiner?.examinationFeedbackCompleted === false && isApprovedExaminer)

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (readOnly || reason !== 'backdropClick') {
          onCancel()
        }
      }}
      maxWidth="lg"
      aria-describedby="dialogContent"
      aria-labelledby="dialogTitle"
    >
      <DialogTitle id="dialogTitle" color="primary" component="h2" variant="h2">
        Written examination feedback
      </DialogTitle>
      <Form formMethods={formMethods} onSubmit={isRnR ? onSubmitRnR : onSubmit}>
        <DialogContent id="dialogContent" sx={{ pt: 0 }}>
          <ExaminerWrittenFeedbackFormInputs examiner={examiner} submission={submission} readOnly={readOnly} />
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 3, pt: 0 }}>
          <Grid container spacing={2}>
            <Grid xs="auto">
              <Button variant="contained" color="secondary" onClick={onCancel} /* disabled={!!loading} */>
                Close
              </Button>
            </Grid>
            {!readOnly && (
              <>
                <Grid xs="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => formMethods.reset()} /* disabled={!!loading} */
                  >
                    reset
                  </Button>
                </Grid>
                <Grid xs="auto">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onSave()}
                    disabled={isPending || !hasModifiedForm}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid xs="auto">
                  <Button variant="contained" color="primary" type="submit" disabled={isPending || hasValidationError}>
                    Submit
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ExaminerReviewDialog
