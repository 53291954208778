import { useEffect, useState } from 'react'

import { Alert } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { GetSubmissionsWithOverdueTasksQuery } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import ContentCard from 'src/components/ContentCard/ContentCard'
import { renderTooltipCell } from 'src/components/DataGrid'
import InlineLoader from 'src/components/InlineLoader/InlineLoader'
import GET_SUBMISSIONS_WITH_OVERDUE_TASKS from 'src/lib/graphql/queries/GetSubmissionsWithOverdueTasksQuery'
import { toTimezone } from 'src/lib/toTimezone'

const examinerColumns: GridColDef[] = [
  { field: 'candidate', headerName: 'Candidate', sortable: true, flex: 1, renderCell: renderTooltipCell },
  {
    field: 'principalAdvisor',
    headerName: 'Principal Advisor',
    sortable: true,
    flex: 1,
    renderCell: renderTooltipCell,
  },
  {
    field: 'chair',
    headerName: 'Chair',
    sortable: true,
    flex: 1,
    renderCell: renderTooltipCell,
  },
  {
    field: 'candidatureInitiatedAt',
    headerName: 'Candidature initiated',
    sortable: true,
    width: 160,
    renderCell: renderTooltipCell,
  },
  {
    field: 'lastStatusUpdate',
    headerName: 'Last status updated',
    sortable: true,
    width: 180,
    renderCell: renderTooltipCell,
  },
  {
    field: 'overdueItem',
    headerName: 'Overdue task',
    sortable: true,
    flex: 1,
    renderCell: renderTooltipCell,
  },
]

const MonitoringPane = () => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 })
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  // Load stored selections when component mounts
  useEffect(() => {
    const storedSelections = localStorage.getItem('selectedRows')
    if (storedSelections) {
      setSelectedRows(JSON.parse(storedSelections))
    }
  }, [])

  // Save selected rows to localStorage
  const handleSelectionChange = (newSelection: readonly string[]) => {
    setSelectedRows(newSelection as string[])
    localStorage.setItem('selectedRows', JSON.stringify(newSelection))
  }

  const { data, loading, error } = useQuery<GetSubmissionsWithOverdueTasksQuery>(GET_SUBMISSIONS_WITH_OVERDUE_TASKS, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <ContentCard title={`Overdue thesis examination tasks `}>
      {error && <Alert severity="error">Could not load overdue thesis examination tasks table</Alert>}
      {loading && <InlineLoader message="Loading overdue thesis examination tasks table..." />}
      {data && (
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastStatusUpdate', sort: 'asc' }],
            },
          }}
          onRowClick={(params) => {
            window.open(
              `${process.env.WEB_BASE_URL}/graduate-school/thesis/details/${params.row.submissionId}`,
              '_blank'
            )
          }}
          columns={examinerColumns}
          rowHeight={80}
          onRowSelectionModelChange={(newSelectionModel) => handleSelectionChange(newSelectionModel)}
          rowSelectionModel={selectedRows} // Persist selections
          rows={data.getSubmissionOverdueTasks.map((item) => {
            return {
              id: item.taskId,
              submissionId: item.submissionId,
              candidate: item.candidate,
              principalAdvisor: item.advisor,
              chair: item.chair,
              candidatureInitiatedAt: toTimezone(item?.candidatureInitiatedAt),
              lastStatusUpdate: toTimezone(item?.lastStatusUpdatedAt),
              overdueItem: item.overdueItem,
            }
          })}
          sx={{ gridRow: { cursor: 'pointer' } }}
        />
      )}
    </ContentCard>
  )
}

export default MonitoringPane
