import { PrivateSet, Route, Router, Set } from '@redwoodjs/router'

import { useAuth } from './auth'
import AdminLayout from './layouts/AdminLayout/AdminLayout'
import GraduateSchoolLayout from './layouts/GraduateSchoolLayout/GraduateSchoolLayout'
import PrimaryLayout from './layouts/PrimaryLayout/PrimaryLayout'
import ProvostLayout from './layouts/ProvostLayout/ThesisChairLayout'
import ResourceHubLayout from './layouts/ResourceHubLayout/ResourceHubLayout'
import SecondaryLayout from './layouts/SecondaryLayout/SecondaryLayout'
import GraduateSchoolDashboardPage from './pages/GraduateSchool/DashboardPage/DashboardPage'
import DeansPage from './pages/GraduateSchool/DeansPage/DeansPage'
import ThesisOverdueUserTasksPage from './pages/GraduateSchool/ThesisOverdueUserTasksPage/ThesisOverdueUserTasksPage'
import ProvostDashboardPage from './pages/Provost/DashboardPage/DashboardPage'
import ThesisChairDetailsPage from './pages/ThesisChairDetailsPage/ThesisChairDetailsPage'
import ThesisExaminerJoinPage from './pages/ThesisExaminerJoinPage/ThesisExaminerJoinPage'
import ThesisExaminersDetailsPage from './pages/ThesisExaminersDetailsPage/ThesisExaminersDetailsPage'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <PrivateSet unauthenticated="login">
        {/** Admin routes */}
        <PrivateSet wrap={AdminLayout} unauthenticated="permissionDenied" roles="admin-dashboard">
          <Route path="/admin" page={AdminDashboardPage} name="adminSite" />
          <Route path="/admin/dashboard" page={AdminDashboardPage} name="adminDashboard" />
          <PrivateSet unauthenticated="permissionDenied" roles={['admin-record-management', 'admin-record-information', 'admin-user-support']}>
            <Route path="/admin/records" page={AdminRecordOverviewPage} name="adminRecordOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles={['admin-record-management', 'admin-record-information']}>
            <Route path="/admin/records/{uuid:String}" page={AdminRecordDetailsPage} name="adminRecordDetails" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-usage-statistics">
            <Route path="/admin/statistics/records" page={AdminRecordStatisticsPage} name="adminRecordStatistics" />
            <Route path="/admin/statistics/users" page={AdminUserStatisticsPage} name="adminUserStatistics" />
            <Route path="/admin/statistics/organisational-units" page={AdminOrganisationalUnitsStatisticsOverviewPage} name="adminOrganisationalUnitsStatisticsOverview" />
            <Route path="/admin/statistics/organisational-units/{id:Int}" page={AdminOrganisationalUnitsStatisticsDetailsPage} name="adminOrganisationalUnitsStatisticsDetails" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-system-statistics">
            <Route path="/admin/statistics/emails" page={AdminEmailStatisticsPage} name="adminEmailStatistics" />
            <Route path="/admin/statistics/user-sessions" page={AdminUserSessionStatisticsPage} name="adminUserSessionStatistics" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-storage-statistics">
            <Route path="/admin/statistics/storage" page={AdminStorageStatisticsPage} name="adminStorageStatistics" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles={['admin-dataset-management', 'admin-dataset-information', 'admin-user-support']}>
            <Route path="/admin/datasets" page={AdminDatasetOverviewPage} name="adminDatasetOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles={['admin-dataset-management', 'admin-dataset-information']}>
            <Route path="/admin/datasets/{id:Int}" page={AdminDatasetDetailsPage} name="adminDatasetDetails" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-group-management">
            <Route path="/admin/user-groups/create" page={AdminUserGroupCreatePage} name="adminUserGroupCreate" />
            <Route path="/admin/user-groups/{id:Int}" page={AdminUserGroupUpdatePage} name="adminUserGroupUpdate" />
            <Route path="/admin/user-groups" page={AdminUserGroupOverviewPage} name="adminUserGroupOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles={['admin-user-management', 'admin-user-information', 'admin-user-support']}>
            <Route path="/admin/users" page={AdminUserOverviewPage} name="adminUserOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles={['admin-user-management', 'admin-user-information']}>
            <Route path="/admin/users/{id:Int}" page={AdminUserSummaryPage} name="adminUserSummary" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-feedback-management">
            <Route path="/admin/feedback/{id:Int}" page={AdminFeedbackDetailsPage} name="adminFeedbackDetails" />
            <Route path="/admin/feedback" page={AdminFeedbackOverviewPage} name="adminFeedbackOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-notification-management">
            <Route path="/admin/notifications/create" page={AdminNotificationCreatePage} name="adminNotificationCreate" />
            <Route path="/admin/notifications/create/{id:Int}" page={AdminNotificationCreatePage} name="adminNotificationClone" />
            <Route path="/admin/notifications/{id:Int}" page={AdminNotificationUpdatePage} name="adminNotificationUpdate" />
            <Route path="/admin/notifications" page={AdminNotificationOverviewPage} name="adminNotificationOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-update-management">
            <Route path="/admin/updates/create" page={AdminUpdateCreatePage} name="adminUpdateCreate" />
            <Route path="/admin/updates/{id:Int}" page={AdminUpdateUpdatePage} name="adminUpdateUpdate" />
            <Route path="/admin/updates" page={AdminUpdateOverviewPage} name="adminUpdateOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-queue-management">
            <Route path="/admin/queue" page={AdminQueueOverviewPage} name="adminQueueOverview" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="developer-access">
            <Route path="/admin/developer-console" page={AdminDeveloperPage} name="adminDeveloperConsole" />
          </PrivateSet>
        </PrivateSet>

        {/** Main routes */}
        <PrivateSet wrap={PrimaryLayout} unauthenticated={'externalLanding'} roles="uq">
          <Route path="/about" page={AboutPage} name="about" />
          <Route path="/create-record" page={CreateRecordNewPage} name="createRecord" />
          <Route path="/create-record/{uuid:string}" page={CreateRecordUpdatePage} name="createRecordUuid" />
          <Route path="/record/{uuid:string}" page={RecordDashboardPage} name="recordDashboard" />
          <Route path="/record/{uuid:string}/publish-dataset" page={PublishDatasetPage} name="publishDataset" />
          <Route path="/dataset/{uuid:string}" page={DatasetDashboardPage} name="datasetDashboard" />
          <Route path="/feedback" page={FeedbackPage} name="feedback" />
          <Route path="/updates" page={UpdatesPage} name="updates" />
          <Route path="/updates/{id:Int}" page={UpdatesPage} name="updateDetails" />

          {/** Dashboard routes */}
          <Route path="/my-records" page={DashboardPage} name="myRecords" />
          <Route path="/my-datasets" page={DashboardPage} name="myDatasets" />
          <Route path="/my-collaborators" page={DashboardPage} name="myCollaborators" />
          <Route path="/" page={DashboardPage} name="dashboard" />

          {/** Thesis routes */}
          <PrivateSet unauthenticated="permissionDenied" roles="user-thesis-submission">
            <Route path="/thesis/{id:Int}" page={ThesisSubmissionDetailsPage} name="thesisSubmissionDetails" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="user-thesis-principal-advisor">
            <Route path="/thesis/principal-advisor/{id:Int}" page={ThesisAdvisorDetailsPage} name="thesisAdvisorDetails" />
          </PrivateSet>
          <PrivateSet unauthenticated="externalLanding" roles="user-thesis-chair">
            <Route path="/thesis/chair/{id:Int}" page={ThesisChairDetailsPage} name="thesisChairDetails" />
          </PrivateSet>
        </PrivateSet>

        <PrivateSet wrap={PrimaryLayout} unauthenticated="externalLanding" roles={['user-thesis-examiner', 'user-thesis-principal-advisor', 'user-thesis-submission', 'user-thesis-chair', 'uq-student']}>
          <Route path="/thesis/dashboard" page={ThesisDashboardPage} name="thesisDashboard" />
        </PrivateSet>

        <PrivateSet wrap={PrimaryLayout} unauthenticated="externalLanding" roles="user-thesis-examiner">
          <Route path="/thesis/examiner/{id:Int}" page={ThesisExaminersDetailsPage} name="thesisExaminerDetails" />
        </PrivateSet>

        {/** File routes */}
        <PrivateSet wrap={SecondaryLayout} unauthenticated="permissionDenied">
          <Route path="/files/{datasetUuid:string}/access-request" page={DatasetFileAccessRequestPage} name="datasetFileAccessRequestPage" />
        </PrivateSet>

        {/** Graduate School routes */}
        <PrivateSet wrap={GraduateSchoolLayout} unauthenticated="permissionDenied" roles="grad-school-dashboard">
          <Route path="/graduate-school" page={GraduateSchoolDashboardPage} name="graduateSchoolSite" />
          <Route path="/graduate-school/dashboard" page={GraduateSchoolDashboardPage} name="graduateSchoolDashboard" />
          <PrivateSet unauthenticated="permissionDenied" roles="grad-school-user">
            <Route path="/graduate-school/thesis/pending" page={GraduateSchoolPendingThesisSubmissionsPage} name="graduateSchoolPendingThesisSubmissions" />
            <Route path="/graduate-school/thesis/pending-examiners" page={GraduateSchoolPendingThesisExaminersPage} name="graduateSchoolPendingThesisExaminers" />
            <Route path="/graduate-school/thesis/details/{id:Int}" page={GraduateSchoolThesisSubmissionDetailsPage} name="graduateSchoolThesisSubmissionDetailsPage" />
            <Route path="/graduate-school/thesis/candidates" page={GraduateSchoolCandidatesPage} name="graduateSchoolCandidates" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles={['grad-school-user', 'grad-school-viewer']}>
            <Route path="/graduate-school/thesis/search" page={GraduateSchoolThesisSubmissionsPage} name="graduateSchoolThesisSubmissions" />
            <Route path="/graduate-school/thesis/completed" page={GraduateSchoolCompletedThesisPage} name="graduateSchoolThesisCompleted" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="admin-grad-school-role-management">
            <Route path="/graduate-school/users" page={GraduateSchoolUsersPage} name="graduateSchoolUsers" />
          </PrivateSet>
          <PrivateSet unauthenticated="permissionDenied" roles="dean-user">
            <Route path="/graduate-school/dean" page={DeansPage} name="graduateSchoolDeans" />
          </PrivateSet>
          {/* @TODO URDM-1211: Remove this once finish testing */}
          <PrivateSet unauthenticated="permissionDenied" roles="restricted-access">
            <Route path="/graduate-school/thesis/statistics/test" page={GraduateSchoolThesisStatisticsTestPage} name="graduateSchoolThesisStatisticsTestPage" />
            <Route path="/graduate-school/thesis/overdue" page={ThesisOverdueUserTasksPage} name="graduateSchoolThesisOverdueUserTasksPage" />
          </PrivateSet>
        </PrivateSet>

        {/** Provost routes */}
        <PrivateSet wrap={ProvostLayout} unauthenticated="permissionDenied" roles="provost-user">
          <Route path="/provost" page={ProvostDashboardPage} name="provostDashboard" />
        </PrivateSet>

        {/** Record Collaborator - Join routes */}
        <Set wrap={SecondaryLayout}>
          <Route path="/join/{joinToken:string}" page={JoinPage} name="joinWelcome" />
        </Set>

        {/** Dataset - Join routes */}
        <Set wrap={SecondaryLayout}>
          <Route path="/dataset/join/{datasetAccessTokenUuid:string}" page={DatasetJoinPage} name="datasetJoinWelcome" />
        </Set>

        {/** Thesis Submission examiner - Join routes */}
        <Set wrap={SecondaryLayout}>
          <Route path="/thesis-examiner/join/{joinToken:string}" page={ThesisExaminerJoinPage} name="thesisExaminerJoinWelcome" />
        </Set>
      </PrivateSet>

      {/** Thesis "public" routes. These are here for future proofing */}
      <PrivateSet unauthenticated="uq-login">
        <Set wrap={SecondaryLayout} noNotifications={true}>
          <Route path="/thesis/download/{uuid:string}" page={ThesisDownloadPage} name="thesisDownload" />
        </Set>
      </PrivateSet>

      {/* Dataset "public" routes */}
      <Set wrap={SecondaryLayout} noNotifications={true}>
        <Route path="/files/{datasetUuid:string}" page={DatasetFilePage} name="datasetFilePage" />
        <Route path="/files/{datasetUuid:string}/download" page={DatasetFileDownloadPage} name="datasetFileDownloadPage" />
      </Set>

      <Set wrap={ResourceHubLayout}>
        <Route path="/resources" page={ResourceHubResourcesPage} name="resourceHub" />
        <Route path="/resources/learn-the-basics" page={ResourceHubLearnTheBasicsPage} name="resourceHubLearnBasics" />
        <Route path="/resources/downloads" page={ResourceHubDownloadsPage} name="resourceHubDownloads" />
        <Route path="/resources/videos" page={ResourceHubVideosPage} name="resourceHubVideos" />
        <Route path="/resources/upcoming-training" page={ResourceHubUpcomingTrainingPage} name="resourceHubUpcomingTraining" />
      </Set>

      <Set wrap={SecondaryLayout} noNotifications={true}>
        <Route path="/uq-login" page={UqLoginPage} name="uq-login" />
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/login-success" page={LoginSuccessPage} name="loginSuccess" />
        <Route path="/logout" page={LogoutPage} name="logout" />
        <Route path="/permission-denied" page={PermissionDeniedPage} name="permissionDenied" />
        <Route path="/unknown-user" page={UnknownUserPage} name="unknownUser" />
        <Route path="/inactive-user" page={InactiveUserPage} name="inactiveUser" />
        <PrivateSet unauthenticated="login">
          <Route path="/external-landing" page={ExternalLandingPage} name="externalLanding" />
        </PrivateSet>
      </Set>

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
