import { AdminPanelSettings } from '@mui/icons-material'

import { routes } from '@redwoodjs/router'

import MenuItem from 'src/types/MenuItem'

const menuItems = (hasRole: (rolesToCheck: string | string[]) => boolean) => {
  const items: MenuItem[] = []

  items.push({
    primaryText: 'Dashboard',
    secondaryText: '',
    linkTo: routes.graduateSchoolDashboard(),
  })

  if (hasRole('grad-school-user')) {
    items.push({
      primaryText: 'Pending thesis submissions',
      secondaryText: '',
      linkTo: routes.graduateSchoolPendingThesisSubmissions(),
    })
    items.push({
      primaryText: 'Pending thesis examiners',
      secondaryText: '',
      linkTo: routes.graduateSchoolPendingThesisExaminers(),
    })
  }

  if (hasRole('grad-school-user') || hasRole('grad-school-viewer')) {
    items.push({
      primaryText: 'Search thesis submissions',
      secondaryText: '',
      linkTo: routes.graduateSchoolThesisSubmissions(),
    })
    items.push({
      primaryText: 'Completed thesis',
      secondaryText: '',
      linkTo: routes.graduateSchoolThesisCompleted(),
    })
  }

  if (hasRole('grad-school-user')) {
    items.push({
      primaryText: 'Candidate management',
      secondaryText: '',
      linkTo: routes.graduateSchoolCandidates(),
    })
  }

  if (hasRole('admin-grad-school-role-management')) {
    items.push({
      primaryText: 'Graduate School Users',
      secondaryText: '',
      linkTo: routes.graduateSchoolUsers(),
    })
  }

  if (hasRole('dean-user')) {
    items.push({
      primaryText: 'Pending Dean Action',
      secondaryText: '',
      linkTo: routes.graduateSchoolDeans(),
    })
  }

  // @TODO URDM-1211: Remove this once finish testing
  // @TODO UQRDM-3026: Remove this once finish testing
  if (hasRole('restricted-access')) {
    items.push({
      primaryText: '[TEST] Thesis submission statistics',
      secondaryText: '',
      linkTo: routes.graduateSchoolThesisStatisticsTestPage(),
    })
    items.push({
      primaryText: '[TEST] Overdue user tasks',
      secondaryText: '',
      linkTo: routes.graduateSchoolThesisOverdueUserTasksPage(),
    })
  }

  items.push({
    primaryText: 'Logout',
    secondaryText: 'Sign out of UQRDM',
    linkTo: routes.logout(),
  })

  if (hasRole('admin-dashboard')) {
    items.push({
      primaryText: 'Admin Dashboard',
      secondaryText: '',
      linkTo: routes.adminDashboard(),
      icon: <AdminPanelSettings fontSize="small" color="disabled" />,
    })
  }

  if (hasRole('provost-user')) {
    items.push({
      primaryText: 'Provost Dashboard',
      secondaryText: 'View and confer theses',
      linkTo: routes.provostDashboard(),
    })
  }

  items.push({
    primaryText: 'Back to UQRDM',
    linkTo: routes.dashboard(),
  })

  return items
}

export default menuItems
