const GET_SUBMISSIONS_WITH_OVERDUE_TASKS = gql`
  query GetSubmissionsWithOverdueTasksQuery {
    getSubmissionOverdueTasks {
      taskId
      submissionId
      candidate
      advisor
      chair
      candidatureInitiatedAt
      lastStatusUpdatedAt
      overdueItem
    }
  }
`

export default GET_SUBMISSIONS_WITH_OVERDUE_TASKS
