import { useState } from 'react'

import { getIterationState } from '@common/thesis/iterationState'
import { Button } from '@mui/material'
import { GetThesisSubmissionQuery } from 'types/graphql'

import ChairReviewDialog from '../../ThesisDetails/ChairSummaryReport/ChairReviewDialog'

type Props = {
  chair: GetThesisSubmissionQuery['getThesisSubmission']['chair'] | null
  submission: GetThesisSubmissionQuery['getThesisSubmission']
  onReload: () => void
  hasAccess: boolean
  isForThesisNotification?: boolean
}

/**
 * Dialog for chair to provide feedback on the the examinations
 */
const ChairFeedbackSummaryButton = ({ chair, submission, onReload, hasAccess, isForThesisNotification }: Props) => {
  const [openReviewForm, setOpenReviewForm] = useState(false)
  const iterationState = getIterationState({
    status: submission.currentIteration?.currentStatus.status,
    outcome: submission.currentIteration?.currentStatus.outcome,
  })
  const isInFinalState = iterationState.thesisState === 'FINAL'
  const isBeforeOralDate = submission.currentIteration.currentStatus.status === 'AWAITING_ORAL_EXAMINATION'
  const chairReportCompleted = submission.chair.summaryReportCompleted

  return (
    <>
      <Button
        variant={
          isBeforeOralDate
            ? isForThesisNotification
              ? 'contained'
              : 'outlined'
            : isForThesisNotification
              ? 'contained'
              : 'contained'
        }
        color={
          isForThesisNotification
            ? isBeforeOralDate
              ? 'secondary'
              : isInFinalState
                ? 'secondary'
                : 'primary'
            : 'primary'
        }
        onClick={() => setOpenReviewForm(true)}
        disabled={!hasAccess}
        size={isForThesisNotification ? 'medium' : 'small'}
      >
        {isInFinalState
          ? 'View report'
          : isBeforeOralDate
            ? 'Preview report'
            : chairReportCompleted
              ? 'Review report'
              : 'Submit report'}
      </Button>
      <ChairReviewDialog
        open={openReviewForm}
        onCancel={() => setOpenReviewForm(false)}
        chair={chair}
        submission={submission}
        onReload={onReload}
      />
    </>
  )
}

export default ChairFeedbackSummaryButton
