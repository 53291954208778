import { Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import PageTitle from 'src/components/Page/PageTitle'
import PageWrapper from 'src/components/Page/PageWrapper'

import MonitoringPane from './components/MonitoringPane'

/**
 * Thesis overdue user tasks page
 */
const ThesisOverdueUserTasksPage = () => {
  return (
    <PageWrapper>
      <Stack direction="column" spacing={2}>
        <PageTitle>Overdue user tasks</PageTitle>
        <Grid container={true} spacing={2}>
          <Grid xs={12}>
            <Typography variant="body2" component={'span'}>
              The following are all the types of overdue user tasks that will be displayed and can be found in this
              table:
              <ul>
                <li>Candidate submission overdue</li>
                <li>Principal Advisor review overdue</li>
                <li>Require chair assignment</li>
                <li>Require two nominated examiners</li>
                <li>Require two approved examiners</li>
                <li>Pending examiner invitation acceptance</li>
                <li>Examiner feedback outstanding</li>
                <li>Chair summary report overdue</li>
                <li>Chair review of corrections overdue</li>
              </ul>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <MonitoringPane />
          </Grid>
        </Grid>
      </Stack>
    </PageWrapper>
  )
}

export default ThesisOverdueUserTasksPage
